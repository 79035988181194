// Polyfills
import "@babel/polyfill";
import "lazysizes";
import "cookieconsent";

window.cookieconsent.initialise({
    "showLink": false,
    "content": {
        "message": "Dette nettstedet bruker cookies for å sikre at du får den beste opplevelsen på nettstedet vårt.",
        "dismiss": "Jeg forstår"
    }
});

// Application files
